import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';

//css
import './App.css';

//components
import Nav from './components/Nav/Nav'
import Footer from './components/Footer/Footer';

//pages
import HomePage from './pages/HomePage';
import TeamPage from './pages/TeamPage';
import WhyGuidedLabsPage from './pages/WhyGuidedLabsPage'
import NotFoundPage from './pages/404';
import ContactPage from './pages/ContactPage';

class App extends React.Component {
	render() {
		return (
			<div className="App">
				<Nav />
	
					<Switch>
						<Route path="/" component={HomePage} exact />
						<Route path="/team" component={TeamPage} />
						<Route path="/whyguidedlabs" component={WhyGuidedLabsPage} />
						<Route path="/contact" component={ContactPage} />
						<Route exact component={NotFoundPage} />
						<Redirect to="/404" />
					</Switch>
				
				<Footer />
			</div>
		);
	}
}

export default App;
