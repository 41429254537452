import React from 'react';

class ContactPage extends React.Component {
	render() {
		return (
			<main className="contact">
				<div className="container">
					<div className="header">
						<h2 className="header__text">Let’s build something great together</h2>
						<p>
							Whatever your ambition, we’d love to design and build your next big idea or lend a hand on
							an existing one.
						</p>
					</div>
					<section className="contact__form">
						<div className="container">
							<form>
								<div className="row">
									<div className="col">
										<div className="input-group">
											<label>First Name</label>
											<input type="text" />
										</div>
									</div>
									<div className="col">
										<div className="input-group">
											<label>Last Name</label>
											<input type="text" />
										</div>
									</div>
								</div>
								<div className="input-group">
									<label>Message</label>
									<textarea />
								</div>
								<div className="submit">
									<button className="btn" type="submit">
										Send Message
									</button>
								</div>
							</form>
						</div>
					</section>
				</div>
			</main>
		);
	}
}

export default ContactPage;
