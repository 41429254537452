import React from 'react';

import { ReactComponent as LaunchIcon } from '../images/LaunchIcon.svg';
import { ReactComponent as BroadcastIcon } from '../images/BroadcastIcon.svg';
import { ReactComponent as FastIcon } from '../images/FastIcon.svg';
import guidedlabssix from '../images/guidedlabs6.jpg';
import guidedlabsseven from '../images/guidedlabs7.jpg';

import guidedlabsthree from '../images/guidedlabs3.jpg'

class WhyGuidedLabsPage extends React.Component {
	render() {
		return (
			<main>
				<section className="hero__header">
					<div className="container">
						<div className="hero__header-text">
							<h1>
								Guided Labs: Transforming Ideas into Reality with Technical and Creative Excellence.
							</h1>
							<p>
								We are a digital strategy firm, helping our clients gain Alpha on their R&D and
								technology investments. We are a group of talented digital professionals, people who are
								dedicated to our crafts and focused on the value we can bring those touched by our work.
								We listen and drill down to the core of our clients’ business; learning about the People
								and Process that make your business return. We call this ACHIEVING PERSPECTIVE. Our
								clients to come to us with open hearts and mouths and entrust us with the problems that
								threaten stop them from reaching their full potential. They trust that through
								process-driven intentionality and client-centered designed, and we'll help them
								implement the optimal solutions.
							</p>
						</div>
					</div>
				</section>
				<section className="images">
					<img src={guidedlabsthree} alt="Guided Labs" />
				</section>
				<section className="introduction">
					<div className="container">
						<div className={`startup-info row wow fadeInUp`} data-wow-offset="10" data-wow-delay="0.7s">
							<div className={`col-4 startup-info__item`}>
								<LaunchIcon />
								<p>
									When startups want to launch their ideas, GuidedLabs gives their concepts the take
									off they need to maximize success.
								</p>
							</div>
							<div className={`col-4 startup-info__item`}>
								<BroadcastIcon />
								<p>
									When startups are ready to broadcast their message to the world, GuidedLabs makes it
									louder and clear, right from the beginning
								</p>
							</div>
							<div className={`col-4 startup-info__item`}>
								<FastIcon />
								<p>
									When startups want to launch their ideas, GuidedLabs gives their concepts the take
									off they need to maximize success.
								</p>
							</div>
						</div>
					</div>
				</section>
				<section className="topic">
					<div className="container">
						<div className="row">
							<div className="col">
								<img src={guidedlabssix} alt="sample" />
							</div>
							<div className="col">
								<h2>Tech that advances the industry</h2>
								<p>
									We are a digital strategy firm, helping our clients gain Alpha on their R&D and
									technology investments. We are a group of talented digital professionals, people who
									are dedicated to our crafts and focused on the value we can bring those touched by
									our work. We listen and drill down to the core of our clients’ business; learning
									about the People and Process that make your business return. We call this ACHIEVING
									PERSPECTIVE. Our clients to come to us with open hearts and mouths and entrust us
									with the problems that threaten stop them from reaching their full potential. They
									trust that through process-driven intentionality and client-centered designed, and
									we'll help them implement the optimal solutions.
								</p>
							</div>
						</div>
					</div>
				</section>
				<section className="topic">
					<div className="container">
						<div className="row">
							<div className="col">
								<h2>Tech that advances the industry</h2>
								<p>
									We are a digital strategy firm, helping our clients gain Alpha on their R&D and
									technology investments. We are a group of talented digital professionals, people who
									are dedicated to our crafts and focused on the value we can bring those touched by
									our work. We listen and drill down to the core of our clients’ business; learning
									about the People and Process that make your business return. We call this ACHIEVING
									PERSPECTIVE. Our clients to come to us with open hearts and mouths and entrust us
									with the problems that threaten stop them from reaching their full potential. They
									trust that through process-driven intentionality and client-centered designed, and
									we'll help them implement the optimal solutions.
								</p>
							</div>
							<div className="col">
								<img src={guidedlabsseven} alt="sample" />
							</div>
						</div>
					</div>
				</section>
			</main>
		);
	}
}

export default WhyGuidedLabsPage;
