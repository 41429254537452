import React from 'react';
import './Footer.css';

import { ReactComponent as Pattern } from '../../images/footerpattern.svg';

function Nav() {
	return (
		<footer>
			<div className="container">
				<div className="footer__top-content" id="project">
					<h2 className="footer__header-text">Let's Build</h2>
					<h5 className="footer__text">
						We're always happy to discuss a new project or collaboration. If you have something in mind or
						just want to say hello, drop us an email.
					</h5>
				</div>
				<div className={`footer__menu row`}>
					<div className={`col-8 footer__links`}>
						<div className="row">
							<div className="col-4">
								<a href="tel:+1-314-437-1212">314.437.1212</a>
								<a href="mailto:info@guidedlabs.io">info@guidedlabs.io</a>
							</div>
							<div className="col-4">
								<p>4625 Lindell Blvd. Ste. 200,</p>
								<p>St. Louis, MO 63108 </p>
							</div>
							<div className="col-4">
								<a href="https://instagram.com/">Instagram</a>
								<a href="https://twitter.com/">Twitter</a>
							</div>
						</div>
					</div>
					<div className={`col-4 copyright`}>
						<span className="copyright__text"> © copyright 2019 All rights reserved </span>
					</div>
				</div>
			</div>
			<Pattern />
		</footer>
	);
}

export default Nav;
