import React from 'react';
import { NavLink } from 'react-router-dom';

import './Nav.css';
import { ReactComponent as Logo } from '../../images/Logo.svg';

class Nav extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			menuIsOpen: true,
			menuToggle: false,
			isTop: true
		};

		this.handleMenuToggle = this.handleMenuToggle.bind(this);
	}

	handleMenuToggle() {
		this.setState({
			menuIsOpen: !this.state.menuIsOpen,
			menuToggle: !this.state.menuToggle
		});
	}

	render() {
		return (
			<nav className={`nav`}>
				<div className="container">
					<div className="navbar">
						<div className="mobile__nav">
							<div className="logo__content" onClick={this.scrollToTop}>
								<NavLink to="/">
									<Logo />
								</NavLink>
							</div>

							<div
								className={`hamburger ${this.state.menuIsOpen ? ' ' : 'is-open'}`}
								onClick={this.handleMenuToggle}
							>
								<div className="hamburger__toggle" />
							</div>
						</div>
						<ul className={`navbar_nav ${this.state.menuToggle ? ' ' : 'hide-nav'}`}>
							<li className="nav_item">
								<NavLink to="/whyguidedlabs" activeClassName="active">Why Guided Labs</NavLink>
							</li>
              <li className="nav_item">
								<NavLink to="/team" activeClassName="active">Team</NavLink>
							</li>
							<li className="nav_item">
								<NavLink to="/contact" activeClassName="active">Contact</NavLink>
							</li>
						</ul>
					</div>
				</div>
			</nav>
		);
	}
}

export default Nav;
