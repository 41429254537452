import React from 'react';
import WOW from 'wowjs';
import { Link } from 'react-router-dom';

//components
import { ReactComponent as Pattern } from '../images/backgroundpatternn.svg';
/* import halatechMockup from '../images/HalaTechMockup.png'; */
import image1 from '../images/guidedlabs1.jpg';
import image2 from '../images/guidedlabs2.jpg';
import image5 from '../images/guidedlabs5.jpg';

class HomePage extends React.Component {
	componentDidMount() {
		//WOW.JS
		const wow = new WOW.WOW({
			mobile: false
		});

		wow.init();
	}

	render() {
		return (
			<div className="home">
				<section className="hero__header">
					<div className="container">
						<div className="hero__header-text">
							<h1>
								<span className="text-blue">Unleash</span> Your Business's Potential with Our <span className="text-blue">Technical Assembly Line</span>{' '}- together we'll bring your <span className="text-blue">boldest ideas</span> to 
								<span className="text-blue"> life</span>.{' '}
							</h1>
							<p>
								At Guided Labs, we blend cutting-edge technology with expert consultancy to 
								bring innovative solutions to real-world challenges. Our team takes a personalized approach, 
								carefully analyzing each client's unique business requirements to deliver 
								customized results. We collaborate with a network of highly skilled specialists who bring a 
								wealth of expertise to every project, ensuring exceptional outcomes every time.
							</p>
						</div>
					</div>
				</section>
				<section className="images">
					<div className="row">
						<div className="col">
							<div className="image1">
								<img src={image1} alt="Guided Labs" />
							</div>
						</div>
						<div className="col">
							<div className="image2">
								<img src={image2} alt="Guided Labs" />
							</div>

							<div className="image3">
								<img src={image5} alt="Guided Labs" />
							</div>
						</div>
					</div>
				</section>
				<section className="capabilities" id="capabilities">
					<div className={`capabilities__content row`}>
						<div className="col">
							<div className="container">
								<div className="header">
									<span>OUR CAPABILITIES</span>
									<h2 className="header__text">One Stop for Ingenuity</h2>
								</div>
								<p>
									When it comes to business these days, technology can the competitive advantage or
									disadvantage that makes or breaks your bottom line. When you deal with Guided Labs,
									rest assured that we have done our due diligence in selecting and mastering the
									right technologies in order to get the job done right.
								</p>

								<p>
									As passionate practitioners of the art of code, we've elected to use only the best
									tools and frameworks to build world-class applications and systems, that are robust,
									distributed, scalable and at home on an array of devices and screens. We come to
									play with the following tools: Ruby (on and off Rails), AngularJs, Twitter
									Bootstrap, CoffeeScript, Less, Postgres, Heroku, and AWS.
								</p>

								<p>Are you looking to have a greater impact? We’d love to help.</p>
							</div>
						</div>
						<div className="col">
							<div className="capabilities__offerings">
								<div className="container">
									<h3>
										<Link to="/team">Digital Transformation Services</Link> /
										<Link to="/team"> Technology Strategy </Link> /
										<Link to="/team"> Business Strategy</Link> /
										<Link to="/team"> Cloud Infastructure Consulting</Link> /
										<Link to="/team"> UX research</Link> / <Link to="/team">User Experience</Link> /
										<Link to="/team"> User Interface </Link> /
										<Link to="/team"> Brand Consulting</Link> /
										<Link to="/team"> Custom Web App Development</Link> /
										<Link to="/team"> Native App Development</Link> /
										<Link to="/team"> Engineering Processes</Link> /
										<Link to="/team"> Staff Augmentation</Link> /
										<Link to="/team"> Technical Project Management</Link>
									</h3>
								</div>
							</div>
						</div>
					</div>
				</section>
				<section className="process" id="process">
					<div className="container">
						<div className="header">
							<span>Our Process</span>
							<h2 className="header__text">
								Here is how we make <br /> magic happen
							</h2>
						</div>
						<div className="process__items">
							<div className="row">
								<div className="col process__item">
									<h3 className="color__blue">01</h3>
									<div className="process__item__content">
										<h3>Discovery</h3>
										<p>
											We ask our clients to come to us with an open heart and mouth and tell us
											everything about their problem and trust that through process-driven
											intentionality. We listen to you. We want to know who you are, understand
											your business and who your audiesnce is. We want to hear your needs and how
											we can build the best solutions for you
										</p>
									</div>
								</div>
								<div className="col process__item">
									<h3 className="color__blue">02</h3>
									<div className="process__item__content">
										<h3>Design</h3>
										<p>
											We ask our clients to come to us with an open heart and mouth and tell us
											everything about their problem and trust that through process-driven
											intentionality. We listen to you. We want to know who you are, understand
											your business and who your audiesnce is. We want to hear your needs and how
											we can build the best solutions for you
										</p>
									</div>
								</div>
								<div className="col process__item">
									<h3 className="color__blue">03</h3>
									<div className="process__item__content">
										<h3>Development</h3>
										<p>
											We ask our clients to come to us with an open heart and mouth and tell us
											everything about their problem and trust that through process-driven
											intentionality. We listen to you. We want to know who you are, understand
											your business and who your audiesnce is. We want to hear your needs and how
											we can build the best solutions for you
										</p>
									</div>
								</div>
								<div className="col process__item">
									<h3 className="color__blue">04</h3>
									<div className="process__item__content">
										<h3>Dedication</h3>
										<p>
											We ask our clients to come to us with an open heart and mouth and tell us
											everything about their problem and trust that through process-driven
											intentionality. We listen to you. We want to know who you are, understand
											your business and who your audiesnce is. We want to hear your needs and how
											we can build the best solutions for you
										</p>
									</div>
								</div>
							</div>
						</div>
					</div>
					<Pattern />
				</section>
				{/* <section className="lab" id="labs">
					<div className="container">
						<div
							className={`lab__content row wow fadeIn`}
							data-wow-duration="2s"
							data-wow-offset="10"
							data-wow-delay="1.2s"
						>
							<div className="col">
								<img src={halatechMockup} alt="HalaTech  Mockup" width="100%" />
							</div>
							<div className="col">
								<div className="header">
									<span>OUR LAB</span>
									<h2 className="header__text">Here is how we make magic happen</h2>
								</div>

								<p>
									We believe that due to our position in this stadium of life, we see can see the
									field from a unique perspective. This perspective offers us the ability to see where
									the application of some of the worlds most advanced technological methodologies and
									practices can turn small opportunities into 21st-century diamond minds. At Guided
									Labs, we spend time researching problems and creating solutions to them.
								</p>
								<a
									className="flagship-product"
									href="https://halatech.io"
									target="_blank"
									rel="noopener noreferrer"
								>
									Learn More about our flagship product
								</a>
							</div>
						</div>
					</div>
				</section> */}
			</div>
		);
	}
}

export default HomePage;
