import React from 'react';

import guidedlabssix from '../images/guidedlabs6.jpg';

class TeamPage extends React.Component {
  render() {
    return (
      <main>
        <section className="hero__header">
          <div className="container">
            <div className="hero__header-text">
              <h1>Our Team</h1>
              <p>No matter the problem at hand, we have the experts who can assist.</p>
            </div>
          </div>
        </section>
        <section className="images">
          <img src={guidedlabssix} alt="Guided Labs" />
        </section>
        <section className="topic">
          <div className="container">
            <h2>Tech that advances the industry</h2>
            <p>
              We are a digital strategy firm, helping our clients gain Alpha on their R&D and technology investments. We
              are a group of talented digital professionals, people who are dedicated to our crafts and focused on the
              value we can bring those touched by our work. We listen and drill down to the core of our clients’
              business; learning about the People and Process that make your business return. We call this ACHIEVING
              PERSPECTIVE. Our clients to come to us with open hearts and mouths and entrust us with the problems that
              threaten stop them from reaching their full potential. They trust that through process-driven
              intentionality and client-centered designed, and we'll help them implement the optimal solutions.
            </p>
          </div>
        </section>
      </main>
    );
  }
}

export default TeamPage;
