import React from 'react';


class NotFoundPage extends React.Component {

  render() {
    return (
            <div>
              <h1>Page Not Found </h1>
            </div>
        );
  }
}

export default NotFoundPage;